import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { East } from "@mui/icons-material";

export function SubscribeEmail() {
  return (
    <Grid
      container
      sx={{
        padding: { xs: "20px 20px", md: "56px 140px" },
        background: "#683C8C",
      }}
    >
      <Grid item xs={12} lg={6}>
        <Typography
          sx={{
            color: "#fff",
            fontFamily: "Roboto",
            fontSize: { xs: "28px", md: "32px" },
            fontWeight: 700,
          }}
        >
          Subscribe to our newsletter to get latest news on your inbox.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            margin: "0 auto",
            gap: "20px",
            paddingLeft: { lg: "100px" },
          }}
        >
          <TextField
            name="email"
            placeholder="Enter your email"
            sx={{
              color: "#1C1B1F",
              borderRadius: "8px",
              background: "#fff",
              "& .MuiInputLabel-root": {
                color: "#1C1B1F !important",
                borderRadius: "8px",
                // padding: "20px", // Adjust label padding
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                padding: "6px", // Adjust input padding
                "& fieldset": {
                  border: "none", // Remove border
                },
                "&:hover fieldset": {
                  border: "none", // Remove border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "none", // Remove border on focus
                },
              },
            }}
            fullWidth
            size="small"
            id="email"
            variant="outlined"
          />

          <Button
            variant="contained"
            sx={{
              padding: "14px 40px",
              cursor: "auto",
              background: "#fff",
              borderRadius: "8px",
              color: "#683C8C",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Inter",
              fontWeight: 400,
              "&:hover": {
                background: "#fff",
                color: "#683C8C",
              },
            }}
          >
            Subscribe <East sx={{ ml: 1 }} />
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
