import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { East, PlayCircleOutline } from "@mui/icons-material";
import youtubeVideoThumbnail from "../assets/images/youtubeVideoThumbnail.png";
import down from "../assets/images/down.svg";

export function BannerArea() {
  const [open, setOpen] = useState(false);
  const videoId = "V3UwjxhbNR8"; // Replace with your video ID
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`; // Thumbnail URL

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleScrollDown = () => {
    const headerHeight = 80; // Height of the header
    window.scrollTo({
      top: window.innerHeight - headerHeight, // Subtract the header height
      behavior: "smooth", // Smooth scrolling
    });
  };

  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px", md: "0" },
        height: { lg: "75vh" },
        position: "relative",
        zIndex: 1,
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          sx={{
            paddingTop: { lg: "100px" },
            paddingLeft: { xs: "0", lg: "140px" },
            textAlign: { xs: "center", lg: "left" },
            zIndex: 1, // Keep it on top in banner area
            position: "relative", // To ensure content stays above the background
          }}
        >
          <Typography
            sx={{
              color: "#683C8C",
              fontSize: { xs: "24px", sm: "32px", md: "36px", lg: "40px" },
              fontFamily: "Roboto",
              fontWeight: 700,
              letterSpacing: "1.7px",
              mb: 2,
            }}
          >
            ONE APP FOR ALL YOUR ERRANDS
          </Typography>
          <Typography
            sx={{
              color: "#683C8C",
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              fontFamily: "Roboto",
              fontWeight: 400,
              marginBottom: "18px",
            }}
          >
            Send and receive almost anything anywhere… We got you covered!
          </Typography>
          <Button
            variant="contained"
            sx={{
              padding: { xs: "8px 12px", md: "10px 20px" },
              background: "#683C8C",
              borderRadius: "12px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Roboto",
              fontWeight: 400,
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
            onClick={handleScrollDown}
          >
            Get Started <East sx={{ ml: 1 }} />
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        sx={{
          marginTop: "60px",
          display: "flex",
          justifyContent: { xs: "center", lg: "end" },
          alignItems: "start",
          paddingRight: { xs: "0", lg: "140px" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            borderRadius: "8px",
            width: { xs: "80%", md: "80%" },
            height: { xs: "auto", lg: "auto" },
          }}
        >
          <img
            src={youtubeVideoThumbnail} // Thumbnail URL
            alt="Video Thumbnail"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
          <IconButton
            onClick={handleOpen}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "rgba(104, 60, 140, 0.2)",
              borderRadius: "50%",
              padding: "10px",
              color: "rgba(104, 60, 140, 1)",
              "&:hover": {
                background: "rgba(104, 60, 140, 0.2)",
              },
            }}
          >
            <PlayCircleOutline sx={{ fontSize: "4rem" }} />
          </IconButton>
          <Modal
            open={open}
            sx={{ width: "100%" }}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
                height: { xs: "50%", sm: "60%", md: "70%", lg: "80%" },
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 0,
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`} // Embed URL with autoplay
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          </Modal>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          position: "absolute",
          bottom: "20px",
          width: "100%",
          textAlign: "center",
          zIndex: 1,
          display: { xs: "none", lg: "block" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={handleScrollDown}
        >
          <img src={down} alt="scroll down icon" />
        </Box>
      </Grid>
    </Grid>
  );
}
