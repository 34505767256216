import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { $crud } from "../utils/CrudFactory";
export function TermsOfUse() {
  const navigate = useNavigate(); // Initialize the navigate function
  const [terms, setTerms] = useState("");
  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/website-terms-use");
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setTerms(res.result[0].content);
      // setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, []);
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Terms of use
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "54px", marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Last updated November 20th, 2022
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          1. Introduction
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Vpickup application is owned and operated by Tractrix Inc. The terms
          “we”, “us”, and “our” refer to Tractrix Inc. The use of our
          application is subject to the following terms and conditions of use,
          as amended from time to time (the “Terms”). The Terms are to be read
          together by you with any terms, conditions or disclaimers provided in
          the pages of our application or website. Please review the Terms
          carefully. The Terms apply to all users of our application and
          website, including without limitation, users who are browsers,
          customers, merchants, vendors and/or contributors of content. If you
          access and use this application or website, you accept and agree to be
          bound by and comply with the Terms and our Privacy Policy. If you do
          not agree to the Terms or our Privacy Policy, you are not authorized
          to access our application and website, use any of our application or
          website’s services or place an order on our application or website.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          2. Use of our application or website
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          You agree to use our application or website for legitimate purposes
          and not for any illegal or unauthorized purpose, including without
          limitation, in violation of any intellectual property or privacy law.
          By agreeing to the Terms, you represent and warrant that you are at
          least the age of majority in your state or province of residence and
          are legally capable of entering into a binding contract.
          <br /> You agree to not use our application or the website to conduct
          any activity that would constitute a civil or criminal offence or
          violate any law. You agree not to attempt to interfere with our
          application or website’s network or security features or to gain
          unauthorized access to our systems.
          <br /> You agree to provide us with accurate personal information,
          such as your email address, mailing address and other contact details
          in order to complete your order or contact you as needed. You agree to
          promptly update your account and information. You authorize us to
          collect and use this information to contact you in accordance with our
          Privacy Policy.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          3. General Conditions
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          We reserve the right to refuse service to anyone, at any time, for any
          reason. We reserve the right to make any modifications to the
          application or website, including terminating, changing, suspending,
          or discontinuing any aspect of the application or website at any time,
          without notice. We may impose additional rules or limits on the use of
          our application or website. You agree to review the Terms regularly
          and your continued access or use of our application or website will
          mean that you agree to any changes. You agree that we will not be
          liable to you or any third party for any modification, suspension or
          discontinuance of our application or website or for any service,
          content, feature, or product offered through our application or
          website.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          4. Products or Services
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          All purchases through our application or website are subject to
          product & service availability. We may, in our sole discretion, limit
          or cancel the services offered on our application or website or limit
          the sales of our products or services to any person, household,
          geographic region or jurisdiction.
          <br /> Prices for our services are subject to change, without notice.
          Unless otherwise indicated, prices displayed on our application or
          website are quoted in Canadian dollars.
          <br /> We reserve the right, in our sole discretion, to refuse orders,
          including without limitation, orders that appear to be placed by
          non-prequalified partners or companies who compete with us. If we
          believe that you have made a false or fraudulent order, we will be
          entitled to cancel the order and inform the relevant authorities.
          <br /> We do not guarantee the accuracy of the time and availability
          of the services offered through our application or website. We have
          made efforts to ensure the time and availability of our services are
          displayed as accurately as possible on our Application or website
          through various methods.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          5. Links to Third-Party Application or websites
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Links from or to Application or websites outside our application or
          website are meant for convenience only. We do not review, endorse,
          approve or control, and are not responsible for any sites linked from
          or to our application or website, the content of those sites, the
          third parties named therein, or their products and services. Linking
          to any other site is at your sole risk and we will not be responsible
          or liable for any damages in connection with linking. Links to
          downloadable software sites are for convenience only and we are not
          responsible or liable for any difficulties or consequences associated
          with downloading the software. Use of any downloaded software is
          governed by the terms of the license agreement, if any, which
          accompanies or is provided with the software.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          6. Use Comments, Feedback, and Other Submissions
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          You acknowledge that you are responsible for the information,
          profiles, opinions, messages, comments and any other content
          (collectively, the “Content”) that you post, distribute or share on or
          through our application or website or services available in connection
          with our application or website. You further acknowledge that you have
          full responsibility for the Content, including but limited to, with
          respect to its legality, and its trademark, copyright and other
          intellectual property ownership. <br /> You agree that any Content
          submitted by you in response to a request by us for a specific
          submission may be edited, adapted, modified, recreated, published, or
          distributed by us. You further agree that we are under no obligation
          to maintain any Content in confidence, to pay compensation for any
          Content or to respond to any Content.
          <br /> You agree that you will not post, distribute or share any
          Content on our application or website that is protected by copyright,
          trademark, patent or any other proprietary right without the express
          consent of the owner of such proprietary right. You further agree that
          your Content will not be unlawful, abusive or obscene nor will it
          contain any malware or computer virus that could affect our
          application or website’s operations. You will be solely liable for any
          Content that you make and its accuracy. We have no responsibility and
          assume no liability for any Content posted by you or any third-party.
          <br /> We reserve the right to terminate your ability to post on our
          application or website and to remove and/or delete any Content that we
          deem objectionable. You consent to such removal and/or deletion and
          waive any claim against us for the removal and/or deletion of your
          Content.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          7. Your Personal Information
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Please see our Privacy Policy to learn about how we collect, use, and
          share your personal information.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          8. Errors and Omissions
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Please note that our application or website may contain typographical
          errors or inaccuracies and may not be complete or current. We reserve
          the right to correct any errors, inaccuracies or omissions and to
          change or update information at any time, without prior notice
          (including after an order has been submitted). Such errors,
          inaccuracies or omissions may relate to product description, pricing,
          promotion and availability and we reserve the right to cancel or
          refuse any order placed based on incorrect pricing or availability
          information, to the extent permitted by applicable law.
          <br /> We do not undertake to update, modify or clarify information on
          our application or website, except as required by law.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          9. Disclaimer and Limitation of Liability
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          You assume all responsibility and risk with respect to your use of our
          application or website, which is provided “as is” without warranties,
          representations or conditions of any kind, either express or implied,
          with regard to information accessed from or via our application or
          website, including without limitation, all content and materials, and
          functions and services provided on our application or website, all of
          which are provided without warranty of any kind, including but not
          limited to warranties concerning the availability, accuracy,
          completeness or usefulness of content or information, uninterrupted
          access, and any warranties of title, non-infringement, merchantability
          or fitness for a particular purpose. We do not warrant that our
          application or website or its functioning or the content and material
          of the services made available thereby will be timely, secure,
          uninterrupted or error-free, that defects will be corrected, or that
          our application or websites or the servers that make our application
          or website available are free of viruses or other harmful components.
          <br />
          The use of our application or website is at your sole risk and you
          assume full responsibility for any costs associated with your use of
          our Application or website. We will not be liable for any damages of
          any kind related to the use of our application or website. In no event
          will we, or our affiliates, our or their respective content or service
          providers, or any of our or their respective directors, officers,
          agents, contractors, suppliers or employees be liable to you for any
          direct, indirect, special, incidental, consequential, exemplary or
          punitive damages, losses or causes of action, or lost revenue, lost
          profits, lost business or sales, or any other type of damage, whether
          based in contract or tort (including negligence), strict liability or
          otherwise, arising from your use of, or the inability to use, or the
          performance of, our Application or website or the content or material
          or functionality through our application or website, even if we are
          advised of the possibility of such damages.
          <br /> Certain jurisdictions do not allow limitation of liability or
          the exclusion or limitation of certain damages. In such jurisdictions,
          some or all of the above disclaimers, exclusions, or limitations, may
          not apply to you and our liability will be limited to the maximum
          extent permitted by law.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          10. Indemnification
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          You agree to defend and indemnify us, and hold us and our affiliates
          harmless,, and our and their respective directors, officers, agents,
          contractors, and employees against any losses, liabilities, claims,
          expenses (including legal fees) in any way arising from, related to or
          in connection with your use of our application or website, your
          violation of the Terms, or the posting or transmission of any
          materials on or through the Application or website by you, including
          but not limited to, any third party claim that any information or
          materials provided by you infringe upon any third party proprietary
          rights.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          11. Entire Agreement
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          The Terms and any documents expressly referred to in them represent
          the entire agreement between you and us in relation to the subject
          matter of the Terms and supersede any prior agreement, understanding
          or arrangement between you and us, whether oral or in writing. Both
          you and we acknowledge that, in entering into these Terms, neither you
          nor we have relied on any representation, undertaking or promise given
          by the other or implied from anything said or written between you and
          us prior to such Terms, except as expressly stated in the Terms.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          12. Waiver
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Our failure to exercise or enforce any right or provision of the Terms
          will not constitute a waiver of such right or provision. A waiver by
          us of any default will not constitute a waiver of any subsequent
          default. No waiver by us is effective unless it is communicated to you
          in writing.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          13. Headings
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Any headings and titles herein are for convenience only.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          14. Severability
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          If any of the provisions of the Terms are determined by any competent
          authority to be invalid, unlawful or unenforceable, such provision
          will to that extent be severed from the remaining Terms, which will
          continue to be valid and enforceable to the fullest extent permitted
          by law.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          15. Governing Law
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Any disputes arising out of or relating to the Terms, the Privacy
          Policy, use of our application or website, or our products or services
          offered on our application or website will be resolved in accordance
          with the laws of= the Province of Ontario without regard to its
          conflict of law rules. Any disputes, actions or proceedings relating
          to the Terms or your access to or use of our application or website
          must be brought before the courts of the Province of Ontario in the
          City of Mississauga <br /> Ontario and you irrevocably consent to the
          exclusive jurisdiction and venue of such courts.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          16. Questions or Concerns
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Please send all questions, comments and feedback to us at
          support@vpickup.com or by using the support link below
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "100px", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            padding: { xs: "8px 12px", md: "5px 10px" },
            background: "#683C8C",
            borderRadius: "8px",
            color: "#fff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "18px",
            "&:hover": {
              background: "#683C8C",
              color: "#fff",
            },
          }}
          onClick={() => handleNavigation("/help")}
        >
          Get Help
        </Button>
      </Grid>
    </Grid>
  );
}
