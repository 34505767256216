import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import doItImg1 from "../assets/images/doItImg1.svg";
import doItImg2 from "../assets/images/doItImg2.svg";
import doItImg3 from "../assets/images/doItImg3.svg";

export function DoItTogether() {
  return (
    <Grid
      container
      sx={{ padding: { xs: "40px", lg: "40px 140px" }, marginBottom: "70px" }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Let’s Do It Together
        </Typography>
        <Typography
          sx={{
            color: "#161C2D",
            fontSize: { xs: "16px", sm: "18px", md: "19px" },
            textAlign: "center",
            fontFamily: "Roboto",
            opacity: "0.7",
            fontWeight: 400,
            marginBottom: "50px",
          }}
        >
          We share common trends and strategies for improving your rental
          income.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "300px", md: "333px" },
            margin: { xs: "30px auto", sm: "30px auto", md: "30px 0", lg: 0 },
          }}
        >
          <Box sx={{ width: "250px", height: "171px" }}>
            <img src={doItImg1} width="100%" height="100%" />
          </Box>
          <Typography
            sx={{
              marginTop: "40px",
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            Become A Client
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: { xs: "16px", md: "17px" },
              fontWeight: 400,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            Book your pickups, deliveries and also earn continuously while your
            referrals are booking
          </Typography>
          <Button
            variant="contained"
            sx={{
              padding: "10px 50px",
              background: "#683C8C",
              borderRadius: "8px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              cursor: "auto",
              fontFamily: "Inter",
              fontWeight: 400,
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
          >
            Register
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "300px", md: "333px" },
            margin: { xs: "30px auto", sm: "30px auto", md: "30px 0", lg: 0 },
          }}
        >
          <Box sx={{ width: "250px", height: "171px" }}>
            <img src={doItImg2} width="100%" height="100%" />
          </Box>
          <Typography
            sx={{
              marginTop: "40px",
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            Become A Rider
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: { xs: "16px", md: "17px" },
              fontWeight: 400,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            Be your own boss! Enjoy flexibility, freedom and competitive
            earnings by delivering with VPickUp.
          </Typography>
          <Button
            variant="contained"
            sx={{
              padding: "10px 50px",
              background: "#683C8C",
              borderRadius: "8px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              cursor: "auto",
              fontFamily: "Inter",
              fontWeight: 400,
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
          >
            Register
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "300px", md: "333px" },
            margin: { xs: "30px auto", sm: "30px auto", md: "30px 0", lg: 0 },
          }}
        >
          <Box sx={{ width: "250px", height: "171px" }}>
            <img src={doItImg3} width="100%" height="100%" />
          </Box>
          <Typography
            sx={{
              marginTop: "40px",
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            Career
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: { xs: "16px", md: "17px" },
              fontWeight: 400,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            Ready for an exciting new challenge? If you’re ambitious, humble,
            and love working with others...
          </Typography>
          <Button
            variant="contained"
            sx={{
              padding: "10px 50px",
              background: "#683C8C",
              borderRadius: "8px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Inter",
              fontWeight: 400,
              cursor: "auto",
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
          >
            Register
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
