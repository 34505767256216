import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function Refund() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Refund & Cancellation Policy
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "54px", marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Updated November 20th, 2022
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Introduction
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Vpickup application is owned and operated by Tractrix Inc. The terms
          “we”, “us”, and “our” refer to Tractrix Inc. The use of our
          application is subject to the following terms and conditions of use,
          as amended from time to time (the “Terms”). The Terms are to be read
          together by you with any terms, conditions or disclaimers provided in
          the pages of our application or website. Please review the Terms
          carefully. The Terms apply to all users of our application and
          website, including without limitation, users who are browsers,
          customers, merchants, vendors and/or contributors of content. If you
          access and use this application or website, you accept and agree to be
          bound by and comply with the Terms and our Privacy Policy. If you do
          not agree to the Terms or our Privacy Policy, you are not authorized
          to access our application and website, use any of our application or
          website’s services or place an order on our application or website
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Customer Cancellation
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          By default, the “Customer” (“a person using the Vpickup Application or
          website to place delivery orders”) should not be entitled to cancel an
          Order once it is placed. Customer may choose to cancel Order only
          before the Order placed is assigned a driver. However, this is subject
          to Customer’s previous cancellation history, Tractrix reserves the
          right to deny any refund to Customer pursuant to a cancellation
          initiated by Customer even if the same is before the order is assigned
          to a picker/driver followed by suspension of account, as may be
          necessary in the sole discretion of Tractrix.
          <br /> If a Customer cancels his/her Order immediately after placing
          the order Tractrix shall have a right to collect a penalty of 100% of
          the Order amount for breach of contract terms as a compensation for
          the damages suffered by Tractrix, with a right to either not to refund
          the Order value in case Customer’s Order is prepaid or recover from
          the Customer’s subsequent Order in case his/her Order is postpaid, to
          compensate the Merchants and Picker’s expense of travel and time
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Non-Customer Cancellation
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Tractrix reserves the right to collect a penalty for the Orders
          constrained to be cancelled by Tractrix for reasons not attributable
          to Tractrix, including but not limited to:
          <ul>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                In the event if the address provided by Customer is either wrong
                or falls outside the accepted delivery address;
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Failure to contact Customer by phone or email at the time of
                delivering the Order booking;
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Failure to deliver Customer’s Order due to lack of information,
                direction or authorization from Customer at the time of
                delivery; or
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Unavailability of all the items ordered by Customer at the time
                of booking the Order; or
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Unavailability of all the items ordered by the Customer at the
                time of booking an Order. However, in the unlikely event of an
                item in an Order being unavailable, Vpickup will contact the
                Buyer on the phone number provided at the time of placing the
                Order and inform Customer of such unavailability. In such an
                event Customer will be entitled to cancel the entire Order and
                shall be entitled to a refund to an amount up to 100% of the
                Order value.
              </Typography>
            </li>
          </ul>
          In case of cancellations for the reasons attributable to Vpickup or
          the Merchant or Picker/Driver, Vpickup shall not collect any penalty
          from the Customer
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Refunds
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          <ul>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Customers may be entitled to a refund for Orders. Vpickup
                retains the right to retain the penalty payable by the Customer
                from the amount refundable to the customer. The Customer shall
                also be entitled to a refund of proportionate value in the event
                packaging of an item in an Order or the complete Order, is
                either tampered or damaged and the Customer refuses to accept at
                the time of delivery for the said reason;
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Customer may be entitled to a refund upto 100% of the Order
                value if the Picker/Driver fails to deliver the Order due to a
                cause from either Picker/Driver or Vpickup, however such refunds
                will be assessed based on evidence and a decision is taken
                depending on the severity of each such case.
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Our decision on refunds shall be final and binding.
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                All refund amounts shall be credited back to the mode of payment
                through Stripe (our payment gateway provider) and would need 5 –
                7 days to reflect on the bank/card statement
              </Typography>
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Specific Terms for purchase of alcohol and cigarettes
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontWeight: 700, textDecoration: "underline" }}>
            Cancellation:
          </span>
          By default, the “Customer” (“a person using the Vpickup Application or
          website to place delivery orders”) should not be entitled to cancel an
          Order once it is placed. If the Customer cancels his/her Order,
          Vpickup shall have a right to collect the Buyer 100% of the Order
          amount as the cancellation fee, with a right to either not to refund
          the Order value in case the Buyer’s Order is prepaid or recover from
          the Buyer’s subsequent Order(s) in case Buyer’s Order is postpaid, to
          compensate the Merchants and the Pickers/Drivers.
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontWeight: 700, textDecoration: "underline" }}>
            Non-Customer Cancellation:
          </span>
          pickup reserves the right to collect the Customer cancellation fee for
          the Orders constrained to be cancelled by Vpickup for reasons not
          attributable to Vpickup or Merchant, including but not limited to:
          <ul>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                failure to contact Customer by phone or email at the time of
                delivering the Order booking;
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                failure to deliver Customer’s Order due to lack of information,
                direction or authorization from Customer at the time of
                delivery; or
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                unavailability of all the items ordered by Customer at the time
                of booking the Order; or
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                unavailability of all the items ordered by the Customer at the
                time of booking an Order. However, in the unlikely event of an
                item in an Order being unavailable, Vpickup will contact the
                Buyer on the phone number provided at the time of placing the
                Order and inform Customer of such unavailability. In such an
                event Customer will be entitled to cancel the entire Order and
                shall be entitled to a refund to an amount up to 100% of the
                Order value.
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                In case of cancellations for the reasons attributable to Vpickup
                or the Merchant or Picker/Driver, Vpickup shall not collect any
                penalty from the Buyer.
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                the Customer’s failure to provide a Passcode to the
                Picker/Driver for receiving delivery
              </Typography>
            </li>
          </ul>
        </Typography>

        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontWeight: 700, textDecoration: "underline" }}>
            Refunds:
          </span>
          The Buyer may be entitled to a refund for prepaid Orders, post
          deduction of cancellation fee, if any for reasons mentioned above or
          in a manner as deemed fit by Vpickup in its sole discretion, if refund
          has been requested due to the following reasons:
          <ul>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                If Vpickup cancels the Order due to reasons not attributable to
                the Customer, including but not limited to non-availability of
                Pickers/Drivers, etc.
              </Typography>
            </li>
          </ul>
          The Customer may be entitled to refund due to the above reasons upto
          100% of the Order value depending upon the nature of issue. Vpickup
          reserves the right to consider the cancellation and refund request and
          determine if such cancellation and refund request satisfy any of the
          above conditions, in which case Vpickup shall process the cancellation
          request and refund to Customer.
          <br />
          Final Terms
          <ul>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                The Customer shall verify his/her Order and the products before
                providing Passcode to the Picker/Driver and any issue with
                respect to the product or Customer’s Order shall be notified
                immediately, and cancellation request shall be placed before
                providing Passcode to the Picker/Driver. Once Passcode is
                provided to the Picker/Driver, it shall be deemed that the Buyer
                has accepted delivery of his/her Order and once he/she has
                accepted delivery, the Customer cannot cancel the Order and/or
                claim refund.
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                In case of the complaint of any spurious product, the liability
                shall solely lie with the Merchant selling the product or person
                passing the item. Vpickup is merely facilitating the
                transactions between the Merchant and the Buyer and therefore,
                Vpickup shall assume no liability with respect to the products
                sold by the Merchant or items picked from other locations.
                Vpickup strictly discourages dealing of any counterfeit product
                on its Platform and shall reserve the right to report such
                incidents to the concerned authorities for appropriate legal
                actions.
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Cancellation of a Pick-up and Drop order after the Driver/Picker
                has reached the location but has not picked up the items may
                invite a cancellation fee in the range of CAD $15 to full
                delivery charge of the order excluding the cost of the item (if
                opted to be paid by Vpickup).
              </Typography>
            </li>
            <li>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Cancellation of a Pick-up and Drop order after the Picker/Driver
                has picked up the delivery items will result in a cancellation
                fee being charged equivalent to the entire service fee.
              </Typography>
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "100px", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            padding: { xs: "8px 12px", md: "5px 10px" },
            background: "#683C8C",
            borderRadius: "8px",
            color: "#fff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "18px",
            "&:hover": {
              background: "#683C8C",
              color: "#fff",
            },
          }}
          onClick={() => handleNavigation("/help")}
        >
          Get Help
        </Button>
      </Grid>
    </Grid>
  );
}
