import { Grid, Typography } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";

export function RegularOffer() {
  return (
    <Grid
      container
      sx={{
        position: "fixed",
        top: 0,
        background: "#fff",
        height: "68px",
        padding: { xs: "20px 0 10px", md: "20px 0 10px" },
        zIndex: 1200,
        backgroundColor: "#fff",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ background: "rgba(104, 60, 140, 1)", padding: "8px" }}
      >
        <Marquee
          gradient={false} // Disable gradient effect for continuous scrolling
          speed={50} // Adjust the speed of the scrolling
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Roboto",
              color: "#fff",
              fontSize: "16px",
            }}
          >
            Discount 20% For New Member, <b> ONLY FOR TODAY!!</b>{" "}
            &nbsp;&nbsp;&nbsp; Special Offer for First Responders!
            &nbsp;&nbsp;&nbsp; Seniors Get 15% Discount! &nbsp;&nbsp;&nbsp; Join
            Now and Get 10% Off! &nbsp;&nbsp;&nbsp;
          </Typography>
        </Marquee>
      </Grid>
    </Grid>
  );
}
