import { Grid, Typography, Box, Button } from "@mui/material";
import commingSoonBg from "../assets/images/comingSoonBg.svg";
import undermaintain from "../assets/images/undermaintain.png";
import React from "react";

export function UnderMaintainence() {
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${commingSoonBg})`, // Set the background image
        backgroundSize: "cover", // Ensure the background covers the entire area
        backgroundPosition: "center", // Center the image
        height: "100vh", // Full viewport height
        padding: { xs: "32px", md: "112px 120px" }, // Responsive padding for main container
        display: "flex", // Enable centering content
        alignItems: "center", // Vertically center content
        justifyContent: "center", // Horizontally center content
        textAlign: "center", // Text in the center
      }}
    >
      {/* Sub-container for centered content */}
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#683C8C", // Background color for the sub-container
          padding: { xs: "20px", md: "50px" }, // Responsive padding for sub-container
          borderRadius: "20px", // Optional: rounded corners for the box
          width: { xs: "100%", sm: "80%", md: "100%" }, // Responsive width
          minHeight: "70vh", // Set the minimum height for the sub-container
          display: "flex", // Flexbox to center the content
          flexDirection: "column", // Stack items vertically
          alignItems: "center", // Center items horizontally
          justifyContent: "center", // Center items vertically
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <img
            src={undermaintain}
            alt="Coming Soon"
            style={{
              position: "absolute",
              bottom: 0,
              right: "-30px",
              maxWidth: "70%", // Control image size responsiveness
              width: "50%", // Responsive width for larger screens
            }}
          />
        </Box>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Typography
              sx={{
                color: "#fff", // White text inside sub-container
                fontFamily: "Roboto",
                fontSize: { xs: "36px", md: "50px" }, // Responsive font size
                fontWeight: 700,
                // marginBottom: "20px",
              }}
            >
              Under Maintenance
            </Typography>

            {/* Subtitle */}
            <Typography
              sx={{
                color: "#fff", // White text inside sub-container
                fontFamily: "Roboto",
                fontSize: { xs: "20px", md: "24px" }, // Responsive font size
                fontWeight: 400,
                marginBottom: "50px",
              }}
            >
              We Will Be Back Soon!.
            </Typography>

            {/* Contact Us Button */}
            <Button
              variant="contained"
              sx={{
                padding: "10px 20px",
                background: "#fff",
                borderRadius: "12px",
                color: "#683C8C",
                textTransform: "capitalize",
                boxShadow: "none",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: "14px",
                cursor: "auto",
                marginBottom: "20px",
                "&:hover": {
                  background: "#fff",
                  color: "#683C8C",
                },
              }}
            >
              Contact Us
            </Button>
            <Typography
              sx={{
                color: "#fff", // White text inside sub-container
                fontFamily: "Roboto",
                fontSize: { xs: "16px", md: "20px" }, // Responsive font size
                fontWeight: 400,
              }}
            >
              Contact: support@vpickup.freshdesk.com
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              textAlign: "center",
              mt: 4,
              display: { xs: "block", lg: "none" },
            }}
          >
            <img
              src={undermaintain}
              alt="Coming Soon"
              style={{
                maxWidth: "70%", // Control image size responsiveness
                width: "70%", // Responsive width for larger screens
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
