import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import location from "../assets/images/location.svg";
import avatar from "../assets/images/avatar.svg";
import React from "react";

export function PickupLocation() {
  return (
    <Grid
      container
      sx={{
        background: "#683C8C",
      }}
    >
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: { xs: "20px", md: "140px" },
          paddingRight: { xs: "20px", md: "100px" },
          paddingTop: { xs: "50px", lg: 0 },
          paddingBottom: { xs: "50px", lg: 0 },
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontFamily: "Roboto",
            fontSize: { xs: "30px", md: "32px" },
            fontWeight: 600,
            textAlign: "center",
            marginBottom: "100px",
          }}
        >
          We will Pick Up and Drop Anything from Anywhere! * <br />
          (Within GTA only for now)
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "60px",
          }}
        >
          <Avatar src={avatar} sx={{ width: "80px", height: "80px" }} />
          <Box>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "20px",
                fontWeight: 400,
                // textAlign: "center",
              }}
            >
              “You made it so simple. My new site is so much faster and easier
              to work with than my old site.”
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "15px",
                fontWeight: 700,
                opacity: "0.65",
                // textAlign: "center",
              }}
            >
              Rhoda Brady
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        // sx={{ textAlign: "center", paddingTop: { md: "70px" } }}
      >
        <Box sx={{ width: { xs: "100%", lg: "100%" } }}>
          <img src={location} style={{ width: "inherit" }} />
        </Box>
      </Grid>
    </Grid>
  );
}
