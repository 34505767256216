import React, { useState } from "react";
import {
  OfferCarousel,
  BannerArea,
  CategoryGrid,
  SupportSection,
  DownloadApp,
  PickupLocation,
  DoItTogether,
  SubscribeEmail,
  BusinessOwner,
  YoutubeVideoSection,
} from "../components";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import next from "../assets/images/next.svg";
import bgBusinessOwner from "../assets/images/bgBusinessOwner.svg";
import bgYoutube from "../assets/images/bgYoutube.svg";
import location from "../assets/images/location.svg";
import youtubeVideoThumbnail from "../assets/images/youtubeVideoThumbnail.png";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

export function HomePage() {
  const [open, setOpen] = useState(false);
  const videoId = "V3UwjxhbNR8"; // Replace with your video ID
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`; // Thumbnail URL

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* --------------------------- BANNER SECTION----------------------- */}
      <BannerArea />
      {/* --------------------------- BANNER SECTION----------------------- */}
      <Grid container>
        {/* ---------------------------HELP OFFER CAROUSEL----------------------- */}
        <Grid item xs={12}>
          <OfferCarousel />
        </Grid>
        {/* ---------------------------HELP OFFER CAROUSEL----------------------- */}

        {/* ---------------------------BUSINESS OWNER SCTION----------------------- */}
        <BusinessOwner />

        {/* ---------------------------BUSINESS OWNER CAROUSEL----------------------- */}

        {/* ------------------------CATEGORIES SECTION------------------------------ */}
        <Grid item xs={12} mb={2}>
          <CategoryGrid />
        </Grid>
        {/* ------------------------CATEGORIES SECTION------------------------------ */}

        {/* ------------------------YOUTUBE VIDEO SECTION------------------------------ */}
        <YoutubeVideoSection />
        {/* ------------------------YOUTUBE VIDEO SECTION------------------------------ */}

        {/* ------------------------PICKUP LOCATION SECTION------------------------------ */}
        <PickupLocation />
        {/* ------------------------PICKUP LOCATION SECTION------------------------------ */}

        {/* ------------------------SUPPORT SECTION------------------------------ */}
        <Grid
          item
          xs={12}
          mb={2}
          sx={{
            marginTop: "90px",
          }}
        >
          <SupportSection />
        </Grid>
        {/* ------------------------SUPPORT SECTION------------------------------ */}
        {/* ------------------------DO IT TOGETHER SECTION------------------------------ */}
        <Grid item xs={12} mb={2}>
          <DoItTogether />
        </Grid>
        {/* ------------------------DO IT TOGETHER SECTION------------------------------ */}
        {/* ------------------------DOWNLOAD APP SECTION------------------------------ */}
        <Grid item xs={12} mb={2} sx={{ marginBottom: 0 }}>
          <DownloadApp />
        </Grid>
        {/* ------------------------DOWNLOAD APP SECTION------------------------------ */}

        {/* ------------------------SUBSCRIBER SECTION------------------------------ */}
        <Grid item xs={12} mb={2} sx={{ marginBottom: 0 }}>
          <SubscribeEmail />
        </Grid>
        {/* ------------------------SUBSCRIBER SECTION------------------------------ */}
      </Grid>
    </>
  );
}
