import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { DoItTogether, DownloadApp, SubscribeEmail } from "../components";
import { useNavigate } from "react-router-dom";

export function About() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "0 20px 0", md: "50px 140px 0" },
          marginTop: {
            xs: "50px",
            lg: "100px",
          },
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "40px",
              fontWeight: 700,
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            About us!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 700,
              opacity: "0.7",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            We're currently working to have this information available to you
            soon and we appreciate your patience.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "30px" }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 400,
              opacity: "0.7",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            Welcome to Vpickup, a subsidiary of Tractrix Inc! We are your
            one-stop solution for all your pickup and delivery needs, ensuring
            convenience and efficiency in your life. Whether you need to send or
            receive laundry, food, cosmetics, documents, packages, electronics,
            or any other item, Vpickup has got you covered. Our dedicated team
            of professionals is committed to going above and beyond for our
            customers, treating every request, big or small, with the utmost
            care.
            <br />
            <br />
            Our app offers a wide range of services tailored to your specific
            requirements. From ordering food, groceries, and essentials online
            from the comfort of your home to accessing late-night delivery
            services, Vpickup has partnered with top restaurants and stores in
            your city, providing you with the best options available. Craving
            your favorite cuisine? Choose from a diverse selection of dishes,
            including Biryani, Pizza, Pasta, Burritos, Burgers, Samosas, Sushi,
            Cakes, and more. With Vpickup, satisfying your taste buds is just a
            few taps away.
            <br />
            <br /> Beyond food delivery, Vpickup also allows you to order from
            nearby or distant supermarkets, ensuring a scheduled delivery time
            that suits your convenience. We prioritize the safety of everyone
            involved in the delivery chain, with our pickers following the best
            hygiene protocols and equipped with masks for on-demand use. For
            added peace of mind, we offer the option of no-contact delivery.
            With no minimum order conditions, you have the freedom to order as
            little or as much as you need, making your life easier when it comes
            to last-minute birthday items, daily essentials, or household goods.
            Enjoy the convenience of ordering from your favorite grocery stores,
            including Walmart, Superstore, Loblaws, and other top-rated
            supercenters in your city, all at fair prices.
            <br />
            <br /> Time management is essential, and Vpickup understands that.
            We offer scheduled deliveries and the flexibility to choose pickup
            and delivery times that align with your availability. Stay informed
            with real-time updates on your order status and estimated time of
            arrival, and easily communicate with your Picker for a seamless
            delivery experience. Distance is never a barrier with Vpickup, as
            you can order from locations far away from your current place,
            expanding your options beyond what other delivery apps offer.
            Whether it’s your friend’s place, a specific store, or your office,
            we ensure your delivery reaches its intended destination.
            <br />
            <br />
            We appreciate your loyalty and strive to provide you with the best
            offers and deals. Take advantage of our freebies, cashback,
            discounts, permanent promos, and other special offers sponsored by
            our host companies and ourselves. Our goal is to help you save big
            while enjoying our exceptional service.
          </Typography>
          <Grid item xs={12} sx={{ marginBottom: "68px", textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                padding: { xs: "8px 12px", md: "5px 80px" },
                background: "#683C8C",
                borderRadius: "8px",
                color: "#fff",
                textTransform: "capitalize",
                boxShadow: "none",
                fontFamily: "Roboto",
                fontWeight: 700,
                fontSize: "18px",
                "&:hover": {
                  background: "#683C8C",
                  color: "#fff",
                },
              }}
              onClick={() => handleNavigation("/help")}
            >
              Get Help
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            textAlign: "center",
            paddingRight: { lg: "80px" },
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              background: "rgba(104, 60, 140, 0.1)",
              padding: "20px",
              margin: "0 auto",
              height: "230px",
              width: { xs: "100%", lg: "300px" },
            }}
          >
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "21px",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              Inception
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontFamily: "Roboto",
                fontSize: "17px",
                fontWeight: 400,
              }}
            >
              Started for a personal purpose and the founder matured it to
              everyone’s purpose by adding more layers to help the community as
              below.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            textAlign: "center",
            padding: { lg: "0 80px" },
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              background: "rgba(104, 60, 140, 0.1)",
              height: "230px",
              padding: "20px",
              width: { xs: "100%", lg: "300px" },
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "21px",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              Our History
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontFamily: "Roboto",
                fontSize: "17px",
                fontWeight: 400,
              }}
            >
              Founded in GTA Canada by the founder of our parent company for his
              personal use.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            textAlign: "center",
            paddingLeft: { lg: "80px" },
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              background: "rgba(104, 60, 140, 0.1)",
              padding: "20px",
              margin: "0 auto",
              height: "230px",
              width: { xs: "100%", lg: "300px" },
            }}
          >
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "21px",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              Our Plan
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontFamily: "Roboto",
                fontSize: "17px",
                fontWeight: 400,
              }}
            >
              Our plan is to help the ones who need our service and the ones
              driving around to help the needful.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <DoItTogether />
      <DownloadApp />
      <SubscribeEmail />
    </>
  );
}
