import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { East } from "@mui/icons-material";
import down from "../assets/images/down.svg";
import heroBg from "../assets/images/heroBg.svg";
import heroImg from "../assets/images/heroImg.svg";

export function BannerArea() {
  const handleScrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <Grid
      container
      sx={{
        padding: { xs: "20px", md: "20px", lg: "0" },
        height: "calc(100vh - 130px)", // Full height minus the header
        minHeight: "75vh",
        position: "relative",
        zIndex: 1,
        backgroundImage: `url(${heroBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          sx={{
            paddingTop: { lg: "100px" },
            paddingLeft: { xs: "0", lg: "140px" },
            textAlign: { xs: "center", lg: "left" },
            zIndex: 1,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              color: "#683C8C",
              fontSize: { xs: "24px", sm: "32px", md: "36px", lg: "40px" },
              fontFamily: "Roboto",
              fontWeight: 700,
              letterSpacing: "1.7px",
              mb: 2,
            }}
          >
            ONE APP FOR ALL YOUR ERRANDS
          </Typography>
          <Typography
            sx={{
              color: "#683C8C",
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              fontFamily: "Roboto",
              fontWeight: 400,
              marginBottom: "18px",
            }}
          >
            Send and receive almost anything anywhere… We got you covered!
          </Typography>
          <Button
            variant="contained"
            sx={{
              padding: { xs: "8px 12px", md: "10px 20px" },
              background: "#683C8C",
              borderRadius: "12px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Roboto",
              fontWeight: 400,
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
            onClick={handleScrollDown}
          >
            Get Started <East sx={{ ml: 1 }} />
          </Button>
        </Box>
      </Grid>

      {/* Image Section */}
      <Grid item xs={12} lg={7}>
        <Box
          sx={{
            width: "100%",
            height: "100%", // Full height of grid
            display: "flex",
            justifyContent: { xs: "center", md: "center", lg: "end" }, // Center horizontally for xs and md
            alignItems: "center", // Center vertically for all screen sizes
            textAlign: "center", // Center text for smaller screens if necessary
          }}
        >
          <img
            style={{ maxWidth: "650px", width: "80%", height: "auto" }}
            src={heroImg}
            alt="Hero"
          />
        </Box>
      </Grid>

      {/* Scroll Down Button */}
      <Grid
        item
        xs={12}
        sx={{
          position: "absolute",
          bottom: "20px",
          width: "100%",
          textAlign: "center",
          zIndex: 1,
          display: { xs: "none", lg: "block" }, // Show only on large screens
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={handleScrollDown}
        >
          <img src={down} alt="scroll down icon" />
        </Box>
      </Grid>
    </Grid>
  );
}
