import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function OutAreaPolicy() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Out of Area Cancellation Conditions & Charges
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "54px", marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Updated - November 20th, 2022
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Policy Statement: We require that at least one pick-up or drop-off
          location for our services be within our designated coverage area, as
          indicated by the “Area” designation in the navigation bar with a
          location icon. This policy aims to ensure efficient and reliable
          service delivery to our customers. In cases where both the pick-up and
          drop-off locations are outside our coverage area, the order will be
          cancelled. Please note that cancellation charges may apply depending
          on the order state.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Policy Guidelines:
        </Typography>
        <ol>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Coverage Area: Our coverage area is clearly marked and indicated
              by the “Area” designation in the navigation bar with a location
              icon. Customers must ensure that at least one of their pick-up or
              drop-off locations falls within this designated coverage area.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Order Cancellation: If both the pick-up and drop-off locations
              provided by the customer are outside our coverage area, the order
              will be cancelled. We prioritize serving customers within our
              coverage area to maintain efficient operations and optimize
              service quality.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Cancellation Charges: In the event of order cancellation due to
              both pick-up and drop-off locations falling outside our coverage
              area, cancellation charges may apply. The specific charges will
              depend on the order state and will be communicated to the customer
              accordingly.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Customer Awareness: By agreeing to use our services, customers
              acknowledge and understand the requirement for at least one
              pick-up or drop-off location to be within our coverage area. It is
              the responsibility of the customer to ensure compliance with this
              policy before placing an order.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Alternative Solutions: If a customer finds that their desired
              pick-up or drop-off location falls outside our coverage area, they
              are encouraged to explore alternative transportation options or
              contact our customer support for assistance in finding suitable
              alternatives.
            </Typography>
          </li>
        </ol>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Policy Enforcement:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Our reviewers will periodically validate the pick-up and drop-off
              locations provided by customers during the order placement
              process.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              In cases where both locations fall outside our coverage area, the
              system will generate an automatic cancellation notification, and
              cancellation charges, if applicable, will be processed
              accordingly.d items are properly sealed and packaged before
              pickup.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Customer support representatives are available to assist customers
              with any inquiries or concerns related to our coverage area
              policy.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Policy Review:
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            opacity: "0.7",
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          This policy will be reviewed periodically to ensure its effectiveness
          and relevance. Any necessary updates or modifications will be made to
          align with changing business needs and customer requirements.
          <br />
          <br />
          By using our services, customers agree to comply with this Pick-up and
          Drop-off Location Policy. Failure to adhere to this policy may result
          in order cancellation and associated charges.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "100px", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            padding: { xs: "8px 12px", md: "5px 10px" },
            background: "#683C8C",
            borderRadius: "8px",
            color: "#fff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "18px",
            "&:hover": {
              background: "#683C8C",
              color: "#fff",
            },
          }}
          onClick={() => handleNavigation("/help")}
        >
          Get Help
        </Button>
      </Grid>
    </Grid>
  );
}
