import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import supportBg from "../assets/images/supportBg.svg";
import mobile from "../assets/images/mobile.svg";
import tick from "../assets/images/tick.svg";
import plan from "../assets/images/plan.svg";
import deliver from "../assets/images/deliver.svg";

export function SupportSection() {
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "0 20px 0", md: "0 140px 10px" },
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            order: { xs: 1, md: 2 },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "100%", lg: "100%" },
              position: "relative",
              display: "flex",
              justifyContent: { xs: "center", sm: "center", lg: "flex-end" },
              alignItems: { xs: "center", sm: "center" },
            }}
          >
            <img
              style={{ width: "80%", maxWidth: "400px" }}
              src={mobile}
              alt="Banner"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            order: { xs: 2, md: 1 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", lg: "flex-start" },
          }}
        >
          <Box sx={{ textAlign: { xs: "center", lg: "left" } }}>
            <Typography
              sx={{
                color: "#161C2D",
                fontSize: "24px",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              {" "}
              Get Productive
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontSize: "17px",
                marginBottom: "20px",
                fontFamily: "Roboto",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Assign your To-Dos to us and focus your time on other productive
              tasks. Make the best use of your time
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontSize: "24px",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              {" "}
              Last Minute Plans
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                marginBottom: "20px",
                fontSize: "17px",
                fontFamily: "Roboto",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Assign your last minute plans to us so you set stage for the
              event/activity. Your final plan is important not who did it.
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontSize: "24px",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              Work With Your Schedules
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontSize: "15px",
                marginBottom: "20px",
                fontFamily: "Roboto",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              You’re in control and informed until your errand is complete. Stay
              on top of the situation!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
