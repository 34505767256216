import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function PromoTerms() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Promo Terms & Conditions
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "54px", marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Updated - November 19th, 2022
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Referral Program
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Name of the promo: Refer a friend
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Code: User-generated email/invitation
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Validity: Ongoing until the account is terminated or deleted from
              Vpickup Systems
            </Typography>
          </li>
        </ul>

        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Only Vpickup registered users are qualified for the referral program
          <br />
          Only one offer can be applied for one unique order <br />
          The referral program cannot be used with any other program
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          cancel/change/modify/add/delete any or all parts of this program at
          any time without any prior notice
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          terminate the program without any notice
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          decline any promotion if we suspect any wrongdoing or abuse in the use
          of this promotion. An explanation is only provided upon written
          request from the clients.
          <br />
          Tractrix Inc. (The parent company of Vpickup) is not liable for any
          abuse caused due to client’s negligence
          <br />
          This agreement is governed by the laws of the Province of Ontario and
          Canada. In case of any dispute concerning this agreement, the parties
          shall attorn to the jurisdiction of the courts of Ontario
          <br />
          Any other queries can be flagged as a ticket through our ticketing
          portal at{" "}
          <span
            style={{ fontWeight: 600, color: "#683C8C", cursor: "pointer" }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://vpickup.freshdesk.com"
              target="_blank"
            >
              https://vpickup.freshdesk.com
            </a>
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Discount Program
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Name of the promo: Save with Vpickup
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Code: System-Generated Code
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Validity: A specified on the promotion
            </Typography>
          </li>
        </ul>

        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Only Vpickup registered businesses are qualified for this promotion
          <br />
          Only one offer can be applied for one unique order
          <br />
          The referral program cannot be used with any other program
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          cancel/change/modify/add/delete any or all parts of this program at
          any time without any prior notice
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          terminate the program without any notice
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          decline any promotion if we suspect any wrongdoing or abuse in the use
          of this promotion. An explanation is only provided upon written
          request from the clients
          <br />
          Offer valid for a single business only.
          <br />
          Offer cannot be clubbed with any other scheme
          <br />
          No cash refunds of coupons/offers are offered through the website or
          the app.
          <br />
          Tractrix Inc. (The parent company of Vpickup) is not liable for any
          abuse caused due to client’s negligence
          <br />
          This agreement is governed by the laws of the Province of Ontario and
          Canada. In case of any dispute concerning this agreement, the parties
          shall attorn to the jurisdiction of the courts of Ontario
          <br />
          Any other queries can be flagged as a ticket through our ticketing
          portal at{" "}
          <span
            style={{ fontWeight: 600, color: "#683C8C", cursor: "pointer" }}
          >
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href="https://vpickup.freshdesk.com"
            >
              https://vpickup.freshdesk.com
            </a>
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Social Benefits promos
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Name of the promo: Discounts For Everyone In Need!
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Code: Assigned to the client account directly
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Validity: 1yr from date of start and renewable each year with
              administrative charges
            </Typography>
          </li>
        </ul>

        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          The client applying for this social benefit promotion should
          completely satisfy all requirements through the verification process
          to qualify for these discounts
          <br />
          Promo can be only applied once on each order
          <br />
          Discount is given based on your case and is applied directly to
          client’s account
          <br />
          Offer cannot be clubbed with any other promotion unless specified
          <br />
          The user will be required to present valid documentation to complete
          the verification process
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          cancel/change/modify/add/delete any or all parts of this program at
          any time without any prior notice
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          terminate the program without any notice
          <br />
          Tractrix Inc. (The parent company of Vpickup) reserves the right to
          decline any promotion if we suspect any wrongdoing or abuse in the use
          of this promotion. An explanation is only provided upon written
          request from the clients
          <br />
          Offer valid for a single business only.
          <br />
          Offer cannot be clubbed with any other scheme
          <br />
          No cash refunds of coupons/offers are offered through the website or
          the app.
          <br />
          Tractrix Inc. (The parent company of Vpickup) is not liable for any
          abuse caused due to client’s negligence
          <br />
          This agreement is governed by the laws of the Province of Ontario and
          Canada. In case of any dispute concerning this agreement, the parties
          shall attorn to the jurisdiction of the courts of Ontario
          <br />
          Any other queries can be flagged as a ticket through our ticketing
          portal at{" "}
          <span
            style={{ fontWeight: 600, color: "#683C8C", cursor: "pointer" }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://vpickup.freshdesk.com"
            >
              https://vpickup.freshdesk.com
            </a>
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "100px", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            padding: { xs: "8px 12px", md: "5px 10px" },
            background: "#683C8C",
            borderRadius: "8px",
            color: "#fff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "18px",
            "&:hover": {
              background: "#683C8C",
              color: "#fff",
            },
          }}
          onClick={() => handleNavigation("/help")}
        >
          Get Help
        </Button>
      </Grid>
    </Grid>
  );
}
