import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function PrivacyPolicy() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Privacy Policy
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "54px", marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Last updated November 20th, 2022
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
          }}
        >
          We are committed to maintaining the accuracy, confidentiality, and
          security of your personally identifiable information (“Personal
          Information” or “PII”). As part of this commitment, our privacy policy
          governs our actions as they relate to the collection, use and
          disclosure of Personal Information. Our privacy policy is based upon
          the values set by the Canadian Standards Association’s Model Code for
          the Protection of Personal Information and Canada’s Personal
          Information Protection and Electronic Documents Act.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          1. Introduction
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Vpickup is owned and operated by Tractrix Inc. We are responsible for
          maintaining and protecting the Personal Information under our control.
          We have designated an individual or individuals who is/are responsible
          for compliance with our privacy policy.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          2. Identifying Purposes
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          We collect, use and disclose Personal Information to provide you with
          the product or service you have requested and to offer you additional
          products and services we believe you might be interested in. The
          purposes for which we collect Personal Information will be identified
          before or at the time we collect the information. In certain
          circumstances, the purposes for which information is collected may be
          clear, and consent may be implied, such as where your name, address
          and payment information is provided as part of the order process.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          3. Consent
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Knowledge and consent are required for the collection, use or
          disclosure of Personal Information except where required or permitted
          by law. Providing us with your Personal Information is always your
          choice. However, your decision not to provide certain information may
          limit our ability to provide you with our products or services. We
          will not require you to consent to the collection, use, or disclosure
          of information as a condition to the supply of a product or service,
          except as required to be able to supply the product or service.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          4. Limiting Collection
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          The Personal Information collected will be limited to those details
          necessary for the purposes identified by us. With your consent, we may
          collect Personal Information from you in person, over the telephone or
          by corresponding with you via mail, facsimile, or the Internet.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          5. Limiting Use, Disclosure and Retention
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Personal Information may only be used or disclosed for the purpose for
          which it was collected unless you have otherwise consented, or when it
          is required or permitted by law. Personal Information will only be
          retained for the period of time required to fulfill the purpose for
          which we collected it or as may be required by law.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          6. Accuracy
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Personal Information will be maintained in as accurate, complete and
          up-to-date form as is necessary to fulfill the purposes for which it
          is to be used.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          7. Safeguarding Customer Information
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Personal Information will be protected by security safeguards that are
          appropriate to the sensitivity level of the information. We take all
          reasonable precautions to protect your Personal Information from any
          loss or unauthorized use, access or disclosure.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          8. Openness
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          We will make information available to you about our policies and
          practices with respect to the management of your Personal Information.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          9. Customer Access
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Upon request, you will be informed of the existence, use and
          disclosure of your Personal Information, and will be given access to
          it. You may verify the accuracy and completeness of your Personal
          Information, and may request that it be amended, if appropriate.
          However, in certain circumstances permitted by law, we will not
          disclose certain information to you. For example, we may not disclose
          information relating to you if other individuals are referenced or if
          there are legal, security or commercial proprietary restrictions.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          10. Handling Customer Complaints and Suggestions
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          You may direct any questions or enquirers with respect to our privacy
          policy or our practices by
        </Typography>
        <Typography
          sx={{
            color: "#683C8C",
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          contacting : support@vpickup.com or using the support platform link at
          the bottom of this page.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Additional Information
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            marginBottom: "20px",
            textDecoration: "underline",
          }}
        >
          Cookies
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          A cookie is a small computer file or piece of information that may be
          stored in your computer's hard drive when you visit our websites or
          applications. We may use cookies to improve our website or
          application's functionality and in some cases, to provide visitors
          with a customized online experience.
          <br />
          Cookies are widely used and most web browsers are configured initially
          to accept cookies automatically. You may change your Internet browser
          settings to prevent your computer from accepting cookies or to notify
          you when you receive a cookie so that you may decline its acceptance.
          Please note, however, if you disable cookies, you may not experience
          optimal performance of our website or application.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            textDecoration: "underline",
          }}
        >
          Other Websites
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Our website may contain links to other third party sites that are not
          governed by this privacy policy. Although we endeavour to only link to
          sites with high privacy standards, our privacy policy will no longer
          apply once you leave our website. Additionally, we are not responsible
          for the privacy practices employed by third party websites. Therefore,
          we suggest that you examine the privacy statements of those sites to
          learn how your information may be collected, used, shared and
          disclosed. How long we retain your data <br />
          If you leave a comment, the comment and its metadata are retained
          indefinitely. This is so we can recognize and approve any follow-up
          comments automatically instead of holding them in a moderation queue.{" "}
          <br />
          For users that register on our website (if any), we also store the
          personal information they provide in their user profile. All users can
          see, edit, or delete their personal information at any time (except
          they cannot change their username). Website administrators can also
          see and edit that information.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            textDecoration: "underline",
          }}
        >
          Media
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          If you upload images to the website, you should avoid uploading images
          with embedded location data (EXIF GPS) included. Visitors to the
          website can download and extract any location data from images on the
          website.
          <br />
          Embedded content from other websites
          <br />
          Articles on this site may include embedded content (e.g. videos,
          images, articles, etc.). Embedded content from other websites behaves
          in the exact same way as if the visitor has visited the other website.
          <br />
          These websites may collect data about you, use cookies, embed
          additional third-party tracking, and monitor your interaction with
          that embedded content, including tracking your interaction with the
          embedded content if you have an account and are logged in to that
          website.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            textDecoration: "underline",
          }}
        >
          Who we share your data with
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          For the purposes of password resets, phone number and email
          verification your IP address will be collected for security reasons
          and the information is transferred through webhook supplied by
          https://make.com
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            textDecoration: "underline",
          }}
        >
          What rights you have over your data
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          If you have an account on this application or website, or have left
          comments, you can request to receive an exported file of the personal
          data we hold about you, including any data you have provided to us.
          You can also request that we erase any personal data we hold about
          you. This does not include any data we are obliged to keep for
          administrative, legal, or security purposes.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "100px", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            padding: { xs: "8px 12px", md: "5px 10px" },
            background: "#683C8C",
            borderRadius: "8px",
            color: "#fff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "18px",
            "&:hover": {
              background: "#683C8C",
              color: "#fff",
            },
          }}
          onClick={() => handleNavigation("/help")}
        >
          Get Help
        </Button>
      </Grid>
    </Grid>
  );
}
