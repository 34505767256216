import { Box, Button, Grid, Typography } from "@mui/material";
import businessOwner from "../assets/images/businessOwner.svg";
import { KeyboardArrowRight } from "@mui/icons-material";

export function BusinessOwner() {
  return (
    <Grid
      container
      sx={{
        padding: { xs: "20px 20px 20px", md: "20px 140px 20px" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
        background: "rgba(104, 60, 140, 0.05)",
      }}
    >
      <Grid
        item
        xs={12}
        lg={4}
        sx={{ textAlign: { xs: "center", lg: "left" } }}
      >
        <Box sx={{ width: { xs: "100%", md: "auto" } }}>
          <img src={businessOwner} style={{ width: "inherit" }} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: { xs: "26px", md: "32px" },
            fontWeight: 700,
            marginBottom: "24px",
            letterSpacing: "0.9",
            marginTop: "16px",
            textAlign: { xs: "center", lg: "left" },
          }}
        >
          Are you a business owner?
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            marginBottom: "16px",
            fontWeight: 400,
            opacity: "0.7",
            textAlign: { xs: "center", lg: "left" },
          }}
        >
          If you’re a restaurant owner stay competitive by offering your regular
          store prices online which you may have increased to cover the food
          delivery apps margins which are charged both to you and the client. We
          don’t charge you anything extra as we collect reasonable amounts from
          the customer/client.
        </Typography>
        <Box
          sx={{
            background: "rgba(104, 60, 140, 0.2)",
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "35px",
          }}
        >
          <Typography
            sx={{
              color: "#683C8C",
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            Try Product Designer, Software Engineer etc.
          </Typography>
          <Button
            variant="contained"
            sx={{
              padding: { xs: "8px 12px", md: "10px 20px" },
              background: "#683C8C",
              borderRadius: "12px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Roboto",
              fontWeight: 700,
              cursor: "auto",
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
          >
            Client Stories <KeyboardArrowRight sx={{ ml: 1 }} />
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
