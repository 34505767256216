import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import cosmetics from "../assets/images/categorySvg/cosmetics.svg";
import medicine from "../assets/images/categorySvg/medicines.svg";
import packageIcon from "../assets/images/categorySvg/package.svg";
import food from "../assets/images/categorySvg/food.svg";
import electronics from "../assets/images/categorySvg/electronic.svg";
import laundry from "../assets/images/categorySvg/laundry.svg";
import homeItems from "../assets/images/categorySvg/homeItems.svg";
import clothes from "../assets/images/categorySvg/clothes.svg";
import groceries from "../assets/images/categorySvg/groceries.svg";
import furniture from "../assets/images/categorySvg/furniture.svg";
import categoryBg from "../assets/images/categoryBg.svg";

const categories = [
  {
    id: 1,
    img: cosmetics,
    label: "Cosmetics",
    description:
      "Leverage agile frameworks provide a robust synopsis  high level overviews. ",
  },
  {
    id: 2,
    img: medicine,
    label: "Prescriptions",
    description:
      "Bring to the table win-win survival strategies to ensure proactive domination.",
  },
  {
    id: 3,
    img: packageIcon,
    label: "Packages & Gifts",
    description:
      " Organically grow the holis world view of disruptive innovation via workplace.",
  },
  {
    id: 4,
    img: food,
    label: "Food",
    description:
      "Nanotechnology immers along the information highway will close the loop .",
  },
  {
    id: 5,
    img: electronics,
    label: "Electronics",
    description:
      "Leverage agile frameworks provide a robust synopsis  high level overviews. ",
  },
  {
    id: 6,
    img: laundry,
    label: "Laundry",
    description:
      " Organically grow the holis world view of disruptive innovation via workplace.",
  },
  {
    id: 7,
    img: homeItems,
    label: "Home Items",
    description:
      " Organically grow the holis world view of disruptive innovation via workplace.",
  },
  {
    id: 8,
    img: clothes,
    label: "Apparel",
    description:
      "Nanotechnology immers along the information highway will close the loop .",
  },
  {
    id: 9,
    img: groceries,
    label: "Groceries",
    description:
      "Nanotechnology immers along the information highway will close the loop .",
  },
  {
    id: 10,
    img: furniture,
    label: "Furniture",
    description:
      "Nanotechnology immers along the information highway will close the loop .",
  },
];

export function CategoryGrid() {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12} sx={{ marginBottom: "70px" }}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Explore All Categories
        </Typography>
        <Typography
          sx={{
            color: "#161C2D",
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            textAlign: "center",
            fontFamily: "Roboto",
            opacity: "0.7",
            fontWeight: 700,
            // marginBottom: "18px",
          }}
        >
          We currently pick these below categories, but we consider anything
          reasonable
        </Typography>
      </Grid>
      {categories.map((c, i) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          key={i}
          sx={{ marginBottom: "35px" }}
        >
          <Box
            sx={{
              margin: { xs: "0 auto", sm: "0 auto", md: 0 },
              borderRadius: "20px",
              padding: "20px",
              border: "1px solid rgba(104, 60, 140, 0.47)",
              transition: "0.2s all ease-in",
              "&:hover": {
                boxShadow: "0px 10px 60px 0px rgba(38, 45, 118, 0.08)",
                borderBottom: "4px solid rgba(104, 60, 140)",
              },
              width: "275px",
              height: { xs: "auto", lg: "327px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content inside the box
              textAlign: "center", // Center text inside the box
            }}
          >
            <img
              src={c.img}
              alt={c.label}
              style={{ width: "100%", height: "170px", objectFit: "cover" }} // Ensure image covers box
            />
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 600,
                mt: 1,
              }}
            >
              {c.label}
            </Typography>
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 400,
                mt: 1,
              }}
            >
              {c.description}
            </Typography>
          </Box>
        </Grid>
      ))}
      {/* </Grid> */}
    </Grid>
  );
}
