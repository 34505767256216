import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function AcceptableItems() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Acceptable Items
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "54px", marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 700,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          Updated - November 20th, 2022
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          <span style={{ color: "#683C8C", fontWeight: 700 }}>Vpickup</span>{" "}
          offers delivery and pickup services for various types of items. To
          ensure a smooth and efficient process, certain guidelines are in place
          for different categories of items
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Food:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Food items must not be open, stale, or restricted due to safety
              and hygiene reasons.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              It is the responsibility of the customer to ensure that the food
              items are properly sealed and packaged before pickup.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Vpickup reserves the right to refuse the pickup of any food item
              that does not meet the specified criteria.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Electronics:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Manufacturer-sealed electronics are acceptable for pickup.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              The driver has the right to deny the pickup of any suspected item
              that appears to be tampered with or conceals illegal objects.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              The electronic item should be in working condition and should
              include all necessary accessories.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              The acceptance of the electronic item is solely at the discretion
              of the driver.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Furniture:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              For the pickup of heavy furniture, customers are required to
              provide advance notice through the Vpickup app.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Adequate packaging and preparation of the furniture for transport
              are the responsibility of the customer.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              Vpickup will not be liable for any damages that may occur during
              the handling or transport of the furniture.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Cosmetics:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Sealed cosmetics with a manufacturer seal are accepted for pickup.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Loose cosmetics must be securely sealed in transparent packaging
              in front of the driver to ensure the integrity of the items.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              The driver may inspect the packaging to verify the contents before
              accepting the pickup.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Laundry:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Customers should pack their laundry items in a bag in front of the
              driver during pickup.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Vpickup assumes no liability for any damage or loss to the clothes
              during transportation or processing.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              It is the customer’s responsibility to ensure that the clothes are
              properly packed and protected.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Grocery:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Items purchased from a store are acceptable for pickup by Vpickup
              drivers.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Grocery items picked up from households should be properly
              packaged to prevent spills, leaks, or damage during transport.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              The driver may request an inspection of the items to ensure their
              condition and suitability for transport.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Household Items:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Open household items such as cooking pots, table lamps, fans,
              etc., are acceptable for pickup as long as they are not tampered
              with and are deemed acceptable by the driver.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              The customer should ensure that any loose parts or accessories are
              securely packed or attached to the item.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            opacity: "0.7",
            // marginBottom: "20px",
          }}
        >
          Apparel:
        </Typography>
        <ul>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              Any apparel is acceptable for pickup as long as it is properly
              packaged.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                opacity: "0.7",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              The customer acknowledges that Vpickup will not be responsible for
              any damages that may occur during the handling or transport of the
              apparel.
            </Typography>
          </li>
          <li>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                opacity: "0.7",
              }}
            >
              By accepting these terms, the client waives any claims for damages
              and agrees that the item is suitable for pickup by the driver.
            </Typography>
          </li>
        </ul>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            marginBottom: "20px",
          }}
        >
          These terms and conditions are subject to change and it is advisable
          to refer to the most up-to-date version provided by Vpickup.
          <br />
          <br />
          In conclusion, Vpickup has established specific terms and conditions
          for accepting various types of items. However, the driver retains the
          right to decline a pickup if deemed necessary, ensuring the safety and
          integrity of the service.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "100px", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            padding: { xs: "8px 12px", md: "5px 10px" },
            background: "#683C8C",
            borderRadius: "8px",
            color: "#fff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "18px",
            "&:hover": {
              background: "#683C8C",
              color: "#fff",
            },
          }}
          onClick={() => handleNavigation("/help")}
        >
          Get Help
        </Button>
      </Grid>
    </Grid>
  );
}
