import { Button, Grid, Typography } from "@mui/material";
import React from "react";

export function PartnerWithUs() {
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          Partner With Us
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          If you think we can somehow partner and help our clients in a better
          way. Please raise a ticket from our support portal and we will assign
          an executive to get in touch with you for a brainstorming session.
        </Typography>
        <Grid item xs={12} sx={{ marginBottom: "40px", textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              padding: { xs: "8px 12px", md: "5px 10px" },
              background: "#683C8C",
              borderRadius: "8px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: "18px",
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
          >
            Get Help
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        sx={{
          textAlign: "center",
          paddingRight: { lg: "80px" },
          marginBottom: "40px",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "21px",
            fontWeight: 700,
            marginBottom: "20px",
          }}
        >
          Inception
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "17px",
            fontWeight: 400,
          }}
        >
          Started for a personal purpose and the founder matured it to
          everyone’s purpose by adding more layers to help the community as
          below.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          textAlign: "center",
          padding: { lg: "0 80px" },
          marginBottom: "40px",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "21px",
            fontWeight: 700,
            marginBottom: "20px",
          }}
        >
          Our History
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "17px",
            fontWeight: 400,
          }}
        >
          Founded in GTA Canada by the founder of our parent company for his
          personal use.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          textAlign: "center",
          paddingLeft: { lg: "80px" },
          marginBottom: "40px",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "21px",
            fontWeight: 700,
            marginBottom: "20px",
          }}
        >
          Our Plan
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "17px",
            fontWeight: 400,
          }}
        >
          Our plan is to help the ones who need our service and the ones driving
          around to help the needful.
        </Typography>
      </Grid>
    </Grid>
  );
}
