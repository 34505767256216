import { Box, Grid, Typography, IconButton } from "@mui/material";
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import carousel1 from "../../assets/images/carousel1.svg";
import carousel2 from "../../assets/images/carousel2.svg";
import carousel3 from "../../assets/images/carousel3.svg";
import carousel4 from "../../assets/images/carousel4.svg";
import carousel5 from "../../assets/images/carousel5.svg";
import carousel6 from "../../assets/images/carousel6.svg";
import carousel7 from "../../assets/images/carousel7.svg";
import next from "../../assets/images/next.svg";
import prev from "../../assets/images/prev.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import {
  East,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

const carouselData = [
  {
    title: "Seniors",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada tristique justo quis ultrices.",
    image: carousel1,
  },
  {
    title: "First Responders",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada tristique justo quis ultrices.",
    image: carousel2,
  },
  {
    title: "Single Parents",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada tristique justo quis ultrices.",
    image: carousel3,
  },
  {
    title: "Special Needs",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada tristique justo quis ultrices.",
    image: carousel4,
  },
  {
    title: "Veterans",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada tristique justo quis ultrices.",
    image: carousel5,
  },
  {
    title: "New Immigrants",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada tristique justo quis ultrices.",
    image: carousel6,
  },
  {
    title: "Int Students",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada tristique justo quis ultrices.",
    image: carousel7,
  },
];

// Duplicate slides to ensure loop mode works
// const duplicatedCarouselData = [
//   ...carouselData,
//   ...carouselData,
//   ...carouselData,
// ];

export function OfferCarousel() {
  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: "100px",
        padding: { xs: "0 20px", md: "0 140px" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "32px",
            fontWeight: 600,
            mb: 1,
          }}
        >
          Discounts For Everyone In Need!
        </Typography>
        <Typography
          sx={{
            color: "#683C8C",
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            fontFamily: "Roboto",
            fontWeight: 400,
            // marginBottom: "18px",
          }}
        >
          It emphasizes the importance of making essential goods and services
          accessible to those who need them most.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            marginTop: "80px",
            position: "relative",
            // padding: "0 20px",
          }}
        >
          <Swiper
            loop={true} // Enable loop mode
            navigation={false} // Disable built-in navigation
            // autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Navigation]}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1500: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            speed={600} // Speed of the slide transition
            ref={swiperRef}
          >
            {carouselData.map((item, i) => (
              <SwiperSlide key={i}>
                <Box
                  sx={{
                    width: "350px",
                    height: "auto",
                    margin: "auto",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Roboto",
                      fontSize: "20px",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "165px",
                      // border: "1px solid #E7E9ED",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "25px",
                    }}
                  >
                    <img src={item.image} alt={item.title} />
                  </Box>

                  <Typography
                    sx={{
                      color: "#4F4F4F",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: 400,
                      opacity: "0.7",
                    }}
                  >
                    {item.description}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#683C8C",
                        fontFamily: "Roboto",
                        fontSize: "17px",
                        fontWeight: 400,
                      }}
                    >
                      Learn more
                    </Typography>
                    <East sx={{ ml: 1, color: "#683C8C" }} />
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <Box
            position="absolute"
            top="45%"
            sx={{ left: { xs: "20px", lg: "-40px" } }}
            zIndex={10}
            transform="translateY(-50%)"
            display="flex"
            alignItems="center"
          >
            <IconButton
              onClick={goPrev}
              sx={{
                background: "#683c8c",
                color: "#fff",
                padding: "10px",
                "&:hover": {
                  color: "#fff",
                  background: "#683c8c",
                },
              }}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </Box>
          <Box
            sx={{ right: { xs: "20px", lg: "-40px" } }}
            position="absolute"
            top="45%"
            zIndex={10}
            transform="translateY(-50%)"
            display="flex"
            alignItems="center"
          >
            <IconButton
              onClick={goNext}
              sx={{
                color: "#fff",
                background: "#683c8c",
                padding: "10px",
                "&:hover": {
                  color: "#fff",
                  background: "#683c8c",
                },
              }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
